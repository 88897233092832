import { Component, OnInit } from '@angular/core';
import { CommonView } from '../shared/util/common-view.util';
import DataSource from 'devextreme/data/data_source';
import { DxLookupDataSourceOptions, DxRowUpdateEvent, DxUtil } from '../shared/util/dx.util';
import { DeterminationRatioService } from './determination-ratio.service';
import { WarehouseService } from '../warehouse/warehouse.service';
import { CarriersService } from '../carriers/carriers.service';
import CustomStore from 'devextreme/data/custom_store';
import { CarrierDeterminationService } from '../carrier-determination/carrier-determination.service';
import { CountriesService } from '../countries/countries.service';
import { DeterminationRatio } from '../shared/models/determination-ratio.model';
import { Privilege } from '../shared/models/privilege.enum';
import { Country } from '../shared/models/country.model';
import { DxFilter } from '@shared-components/shopsys-commons-ui';
import { ZoneService } from '../zone/zone.service';
import { Zone } from '../shared/models/zone.model';
import { ArticleBrickCodeRule } from '../shared/models/article-brick-code-rule.model';

@Component({
    selector: 'kep-determination-ratio',
    templateUrl: './determination-ratio.component.html',
})
export class DeterminationRatioComponent extends CommonView implements OnInit {

    Privilege = Privilege;
    dataSource: DataSource;
    salesChannels: CustomStore;
    warehouseStore: CustomStore;
    countryStore: CustomStore;
    carrierStore: CustomStore;
    deliveryOptionStore: CustomStore;
    zonesStore: CustomStore;
    filteredZones: CustomStore;
    zones: Zone[];

    readonly allowedPageSizes = [10, 20, 50, 75, 100, 1000];
    headerCountryIdFilterData: { text?: string; value?: string }[] = [];

    resetCarrierCell = DxUtil.hookSetCellValue<DeterminationRatio>(rowData => {
        rowData.carrierId = null;
    });

    constructor(
        private determinationRatioService: DeterminationRatioService,
        private countryService: CountriesService,
        private warehouseService: WarehouseService,
        private carrierService: CarriersService,
        private carrierDeterminationService: CarrierDeterminationService,
        private zonesService: ZoneService,
    ) {
        super();
    }

    ngOnInit() {
        this.dataSource = this.determinationRatioService.getDeterminationRatios();
        this.countryStore = this.countryService.getCountriesAsCustomStore();
        this.carrierStore = this.carrierService.getCarriersAsCustomStore();
        this.warehouseStore = this.warehouseService.getWarehouseAsCustomStore();
        this.deliveryOptionStore = this.carrierDeterminationService.getDeliveryOptions();
        this.salesChannels = this.carrierDeterminationService.getSalesChannels();
        this.countryService.getCountriesAsPromise().then(this.initializeHeaderCountryIdFilterData);
        this.zonesStore = this.zonesService.getZonesAsCustomStore();
        this.filteredZones = this.zonesStore;
        this.zonesService.getZonesAsPromise().then(zones => this.zones = zones);
    }
    private initializeHeaderCountryIdFilterData = (country: Country[]) => {
        this.headerCountryIdFilterData = country.map(item => ({ text: item.countryCode, value: item.id }));
    };

    initNewRow(e) {
        e.data.costOptimizationShare = 0;
        e.data.share = 0;
    }

    onRowUpdate(e: DxRowUpdateEvent<DeterminationRatio>) {
        if (e.newData.hasOwnProperty('costOptimizationShare') && e.newData.costOptimizationShare == null) {
            e.newData.costOptimizationShare = 0;
        }
        DxUtil.fillUpNewDataOnUpdate(e);
    }

    rowInsert(e) {
        e.data.startTime = this.formatDate(e.data.startTime);
        e.data.endTime = this.formatActiveSlotEnd(e.data.endTime);
        if (e.data.costOptimizationShare == null) {
            e.data.costOptimizationShare = 0;
        }
    }

    formatDate(date) {
        if (date === undefined) {
            return '00:00:00';
        }
        return ('00' + date.getHours()).slice(-2)
            + ':' + ('00' + date.getMinutes()).slice(-2)
            + ':' + ('00' + date.getSeconds()).slice(-2);
    }

    formatActiveSlotEnd(date) {
        if (date === undefined) {
            return '23:59:59';
        }
        return ('00' + date.getHours()).slice(-2)
            + ':' + ('00' + date.getMinutes()).slice(-2)
            + ':' + ('00' + date.getSeconds()).slice(-2);
    }
    onEditorPreparing(e) {
        e.editorOptions.onValueChanged = data => {
            e.setValue(data.value);
            this.isDirty = true;
        };

        if (e.parentType === 'dataRow' && e.dataField === 'countryId') {
            if (!!e.value) {
                this.filteredZones = this.getZonesByCountryId(e.value);
            }
            e.editorOptions.onValueChanged = data => {
                e.setValue(data.value);
                this.isDirty = true;
                this.filteredZones = this.getZonesByCountryId(data.value);
            };
        }
    }

    getZonesByCountryId(countryId: string): CustomStore {
        if (!countryId) {
            return this.zonesStore;
        }
        return this.zonesService.getZonesByCountryAsCustomStore(countryId);
    }

    getZoneName(zoneId: string): string {
        if (!zoneId) {
            return null;
        }
        return this.zones.find( zone => zone.id === zoneId).name;
    }

    calculateZoneFilterExpression(filterValue: string): string[] {
        return ['zones', 'contains', filterValue];
    }

    calculateCountryFilterExpression(filterValue: string, filterOperation: string): string[] {
        return ['country.id', filterOperation, filterValue];
    }

    calculateCarrierFilterExpression(filterValue: string, filterOperation: string): string[] {
        return ['carrier.id', filterOperation, filterValue];
    }

    calculateWarehouseFilterExpression(filterValue: string, filterOperation: string): string[] {
        return ['warehouse.id', filterOperation, filterValue];
    }

    getFilteredCarrier = (options: DxLookupDataSourceOptions<DeterminationRatio>) => {
        if (options.data == null) {
            return { store: this.carrierStore };
        }

        const country = DxUtil.fetchLookupObject<DeterminationRatio, Country>(options, 'countryId');

        const filter = DxFilter.and(
            DxFilter.equals('country.id', country ? country.id : null),
        );

        return {
            store: this.carrierStore,
            filter,
        };
    };
    getFilteredZones = (options: DxLookupDataSourceOptions<ArticleBrickCodeRule>) => {
        if (options.data == null) {
            return { store: this.zonesStore };
        }

        const country = DxUtil.fetchLookupObject<ArticleBrickCodeRule, Country>(options, 'countryId');

        const filter = DxFilter.equals('country.id', country ? country.id : null);

        return {
            store: this.zonesStore,
            filter,
        };
    };

}
